import React, { useState,useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs,setDoc, deleteDoc, doc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { initializeApp } from 'firebase/app';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import '../css/AddUserPage.css';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAyzAXx7lXVKVNoSfKuzbz8cPx47tT-nXM",
  authDomain: "zi-goals.firebaseapp.com",
  projectId: "zi-goals",
  storageBucket: "zi-goals.appspot.com",
  messagingSenderId: "870416339071",
  appId: "1:870416339071:web:6b4f84985abefec96baf4a"
};
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

const AddUserPage = ({ editingUser, onClose }) => {
  const [file, setFile] = useState(null);
  const [user, setUser] = useState(null);
  const [manualUser, setManualUser] = useState({
    contactNo: '',
    name: '',
    subscription: '',
    class: '',
    rollNo: '',
    paidAmount: '',
    paidDate: '',
    subscriptionEndDate: '',
    email: '',         // Added email field
    gender: ''         // Added gender field
  });
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (editingUser) {
      setManualUser({
        contactNo: editingUser.contactNo || '',
        name: editingUser.name || '',
        subscription: editingUser.subscription || '',
        class: editingUser.class || '',
        rollNo: editingUser.rollNo || '',
        paidAmount: editingUser.paidAmount || '',
        paidDate: editingUser.paidDate || '',
        subscriptionEndDate: editingUser.subscriptionEndDate || '',
        email: editingUser.email || '', // Set email from editingUser
        gender: editingUser.gender || '' // Set gender from editingUser
      });
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [editingUser]);

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const date = moment(dateString, ["YYYY-MM-DD", "MM/DD/YYYY", "DD/MM/YYYY", "YYYY-MM-DDTHH:mm:ssZ"], true);
    return date.isValid() ? date.toDate() : null;
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert('Please select a file.');
      return;
    }
  
    if (!user) {
      alert('No logged-in user found.');
      return;
    }
  
    const reader = new FileReader();
    reader.onload = async (e) => {
      const csvData = e.target.result;
      Papa.parse(csvData, {
        header: true,
        complete: async (results) => {
          const rows = results.data;
          console.log('Parsed CSV Rows:', rows);
  
          for (const row of rows) {
            try {
              const uniqueId = uuidv4(); // Generate a unique ID
              const subscriptionEndDate = calculateSubscriptionEndDate(row['paidDate'], row['subscription']);
              const contactNo = row['contactNo'];
              console.log(contactNo);
              if (!contactNo) {
                console.warn('Skipping row without contact number:', row);
                continue; // Skip rows without a contact number
              }
  
              const formattedData = {
                contactNo: contactNo,
                name: row['name'] || '',
                subscription: row['subscription'] || '',
                class: row['class'] || '',
                rollNo: generateRollNo(),
                paidAmount: row['paidAmount'] || '',
                paidDate: parseDate(row['paidDate']),
                subscriptionEndDate: subscriptionEndDate,
                email: row['email'] || '', // Added email field
                gender: row['gender'] || '', // Added gender field
                insertDate: new Date(), // Add current date and time
                insertedBy: user.email // Add logged-in user's email
              };
  
              console.log('Uploading Data:', formattedData);
  
              await setDoc(doc(firestore, 'addusers', uniqueId), formattedData);
              console.log('Document successfully written!', formattedData);
            } catch (error) {
              console.error('Error adding document:', error);
            }
          }
  
          alert('Users added successfully!');
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        }
      });
    };
  
    reader.readAsText(file);
  };

  const generateRollNo = () => {
    return `RN${Math.floor(Math.random() * 100000)}`;
  };

  const calculateSubscriptionEndDate = (startDate, subscriptionDuration) => {
    const start = moment(startDate);
    let endDate = start;
    switch (subscriptionDuration) {
      case '1 month':
        endDate = start.add(1, 'months');
        break;
      case '3 months':
        endDate = start.add(3, 'months');
        break;
      case '6 months':
        endDate = start.add(6, 'months');
        break;
      case '12 months': // Added case for 12 months
        endDate = start.add(12, 'months');
        break;
      default:
        break;
    }
    return endDate.toDate();
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      alert('No logged-in user found.');
      return;
    }

    const uniqueId = uuidv4(); // Generate a unique ID
    const rollNo = generateRollNo();
    const subscriptionEndDate = calculateSubscriptionEndDate(manualUser.paidDate, manualUser.subscription);
    const contactNo = manualUser.contactNo;

    if (!contactNo) {
      alert('Please enter a contact number.');
      return;
    }

    const formattedData = {
      ...manualUser,
      rollNo: rollNo,
      paidDate: parseDate(manualUser.paidDate),
      subscriptionEndDate: subscriptionEndDate,
      insertDate: new Date(), // Add current date and time
      insertedBy: user.email // Add logged-in user's email
    };

    try {
      if (isEditing) {
        // Update existing user document
        const userDoc = doc(firestore, 'addusers', uniqueId);
        await updateDoc(userDoc, formattedData);
        alert('User details updated successfully!');
      } else {
        // Add new user document
        await setDoc(doc(firestore, 'addusers', uniqueId), formattedData);
        alert('User added successfully!');
      }
      setManualUser({
        contactNo: '',
        name: '',
        subscription: '',
        class: '',
        rollNo: '',
        paidAmount: '',
        paidDate: '',
        subscriptionEndDate: '',
        email: '',         // Reset email field
        gender: ''         // Reset gender field
      });
      setIsFormVisible(false);
      onClose(); // Close the form or navigate back
    } catch (error) {
      console.error('Error saving document:', error);
    }
  };

  return (
    <div className="container">
      <div className="content">
        <div className="uploadSection">
          <h2>Upload Users from CSV</h2>
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <p>Drag 'n' drop a CSV file here, or click to select one</p>
            {file && <p>Selected file: {file.name}</p>}
          </div>
          <button className="button" onClick={handleFileUpload}>Upload and Add Users</button>
        </div>

        <div className="manualSection">
          <h2>{isEditing ? 'Edit User' : 'Or Add User Manually'}</h2>
          <button className="add-button" onClick={() => setIsFormVisible(!isFormVisible)}>
            {isEditing ? 'Cancel' : '+'}
          </button>
          {isFormVisible && (
            <form onSubmit={handleManualSubmit} className="form">
              <input
                type="text"
                placeholder="Contact No"
                value={manualUser.contactNo}
                onChange={(e) => setManualUser({ ...manualUser, contactNo: e.target.value })}
                required
                className="input"
                disabled={isEditing} // Disable if editing
              />
              <input
                type="text"
                placeholder="Name"
                value={manualUser.name}
                onChange={(e) => setManualUser({ ...manualUser, name: e.target.value })}
                required
                className="input"
              />
              <input
                type="text"
                placeholder="Class"
                value={manualUser.class}
                onChange={(e) => setManualUser({ ...manualUser, class: e.target.value })}
                required
                className="input"
              />
              <select
                value={manualUser.subscription}
                onChange={(e) => setManualUser({ ...manualUser, subscription: e.target.value })}
                required
                className="input"
              >
                <option value="">Select Subscription</option>
                <option value="1 month">1 Month</option>
                <option value="3 months">3 Months</option>
                <option value="6 months">6 Months</option>
                <option value="12 months">12 Months</option> {/* Added option for 12 months */}
              </select>
              <input
                type="text"
                placeholder="Paid Amount"
                value={manualUser.paidAmount}
                onChange={(e) => setManualUser({ ...manualUser, paidAmount: e.target.value })}
                required
                className="input"
              />
              <input
                type="date"
                placeholder="Paid Date"
                value={manualUser.paidDate}
                onChange={(e) => setManualUser({ ...manualUser, paidDate: e.target.value })}
                required
                className="input"
              />
              <input
                type="email"
                placeholder="Email"
                value={manualUser.email}
                onChange={(e) => setManualUser({ ...manualUser, email: e.target.value })}
                required
                className="input"
              />
              <select
                value={manualUser.gender}
                onChange={(e) => setManualUser({ ...manualUser, gender: e.target.value })}
                required
                className="input"
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              <button type="submit" className="button">
                {isEditing ? 'Update User' : 'Add User Manually'}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddUserPage;