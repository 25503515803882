import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet,Dimensions ,ImageBackground,ScrollView,Image } from 'react-native';
import { getFirestore, collection, onSnapshot, query, where,doc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import { PieChart } from 'react-native-chart-kit';
import Icon from 'react-native-vector-icons/FontAwesome';
import CustomDrawerContent from './CustomerDrawerContent';
// import DoughnutChart from './DoughnutChart';
import moment from 'moment';
import { BarChart, XAxis, YAxis } from 'react-native-svg-charts';
import { Svg, G, Rect } from 'react-native-svg';
import { parseISO, isBefore, differenceInDays, startOfMonth, endOfMonth } from 'date-fns';
import { Grid, Typography } from '@mui/material';

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyAyzAXx7lXVKVNoSfKuzbz8cPx47tT-nXM",
    authDomain: "zi-goals.firebaseapp.com",
    projectId: "zi-goals",
    storageBucket: "zi-goals.appspot.com",
    messagingSenderId: "870416339071",
    appId: "1:870416339071:web:6b4f84985abefec96baf4a"
  };

  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);
  const auth = getAuth(app);
  const { width } = Dimensions.get('window');
  const iconSize = width * 0.04;
  
  const DashboardPage = () => {
    const [userCount, setUserCount] = useState(0);
    const [activeUserCount, setActiveUserCount] = useState(0);
    const [followUpCount, setFollowUpCount] = useState(0);
    const [subscriptionEndUserCount, setSubscriptionEndUserCount] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);
    const [cumulativeUserData, setCumulativeUserData] = useState([]);
  
    useEffect(() => {
      const unsubscribeAuth = onAuthStateChanged(auth, user => {
        if (user) {
          setCurrentUser(user);
        } else {
          setCurrentUser(null);
        }
      });
  
      return () => unsubscribeAuth();
    }, []);
  
    useEffect(() => {
      if (currentUser) {
        const userDocRef = doc(firestore, 'addusers', currentUser.email);
  
        const unsubscribeUsers = onSnapshot(userDocRef, docSnapshot => {
          if (docSnapshot.exists()) {
            const users = docSnapshot.data().users || [];
  
            // Update user counts
            const today = new Date();
            const activeUsers = users.filter(user => new Date(user.subscriptionEndDate) > today);
            const followUpUsers = users.filter(user => {
              const subscriptionDate = new Date(user.subscriptionEndDate);
              const diffDays = differenceInDays(subscriptionDate, today);
              return diffDays <= 7 && diffDays > 0;
            });
            const subscriptionEndUsers = users.filter(user => new Date(user.subscriptionEndDate) < today);
  
            setUserCount(users.length);
            setActiveUserCount(activeUsers.length);
            setFollowUpCount(followUpUsers.length);
            setSubscriptionEndUserCount(subscriptionEndUsers.length);
  
            // Calculate cumulative users count based on paidDate
            const cumulativeCounts = Array(12).fill(0); // Array for each month
            users.forEach(user => {
              if (user.paidDate) {
                const paidDate = parseISO(user.paidDate);
                const month = paidDate.getMonth();
                cumulativeCounts[month] += 1;
              }
            });
            setCumulativeUserData(cumulativeCounts);
          } else {
            console.log('No users found for this email');
          }
        });
  
        return () => unsubscribeUsers();
      }
    }, [currentUser]);
  
    return (
      <ScrollView style={styles.container}>
        <View style={styles.metricContainer}>
          <View style={styles.metricBox}>
            <Image source={require('../../assets/icons/totalusers.png')} style={styles.icon} />
            <Text style={styles.metricLabel}>Total Users</Text>
            <Text style={styles.metricValue}>{userCount}</Text>
          </View>
          <View style={styles.metricBox}>
            <Image source={require('../../assets/icons/activeusers.png')} style={styles.icon} />
            <Text style={styles.metricLabel}>Active Users</Text>
            <Text style={styles.metricValue}>{activeUserCount}</Text>
          </View>
          <View style={styles.metricBox}>
            <Image source={require('../../assets/icons/followupusers.png')} style={styles.icon} />
            <Text style={styles.metricLabel}>Follow-ups (7 days)</Text>
            <Text style={styles.metricValue}>{followUpCount}</Text>
          </View>
          <View style={styles.metricBox}>
            <Image source={require('../../assets/icons/subscriptionenduser.png')} style={styles.icon} />
            <Text style={styles.metricLabel}>Subscription End Users</Text>
            <Text style={styles.metricValue}>{subscriptionEndUserCount}</Text>
          </View>
        </View>
        {/* <Text style={styles.chartTitle}>Cumulative Users Count (Past Year)</Text>
        <BarChart
          style={styles.chart}
          data={cumulativeUserData}
          contentInset={{ top: 30, bottom: 30 }}
          svg={{ fill: '#FF5757' }}
          spacing={0.2}
          gridMin={0}
          xAccessor={({ index }) => index}
          yAccessor={({ item }) => item}
          yScale="linear"
        >
          <Grid />
        </BarChart> */}
      </ScrollView>
    );
  };
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      padding: 20,
      backgroundColor: '#fff',
    },
    metricContainer: {
      backgroundColor: '#fff',
      padding: 20,
      borderRadius: 10,
      elevation: 3,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    metricBox: {
      padding: 15,
      marginBottom: 15,
      borderRadius: 8,
      backgroundColor: '#fff',
      borderWidth: 2,
      borderColor: '#ff5757',
      flexDirection: 'row',
      alignItems: 'center',
      width: '48%',
      minWidth: 150,
    },
    icon: {
      width: iconSize,
      height: iconSize,
      marginRight: 16,
      resizeMode: 'contain',
    },
    metricLabel: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#545454',
      flex: 1,
    },
    metricValue: {
      fontSize: 24,
      fontWeight: 'bold',
      color: '#FF5757',
    },
    chartTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#545454',
      alignSelf: 'center',
      marginVertical: 10,
    },
    chart: {
      height: 220,
      width: width * 0.9,
      alignSelf: 'center',
      marginVertical: 8,
    },
  });
  
  export default DashboardPage;