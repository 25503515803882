import React, { useState, useEffect } from 'react';
import {Alert, View, Text, TextInput, Button, StyleSheet, ScrollView,ImageBackground ,Dimensions } from 'react-native';
import { initializeApp } from '@firebase/app';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from '@firebase/auth';
import { getFirestore, doc, setDoc, getDoc, updateDoc, serverTimestamp, collection, query, where, getDocs } from 'firebase/firestore';
import { createStackNavigator } from '@react-navigation/stack';
import Svg, { Path } from 'react-native-svg';
import DashboardPage from './Apps/Screens/DashboardPage';
import AdminPage from './Apps/Screens/AdminPage';
import AddUserPage from './Apps/Screens/AddUserPage';
import ExpensePage from './Apps/Screens/ExpensePage';
import SubscriptionPlans from './Apps/Screens/SubscriptionPlans';
import StaffAttendance from './Apps/Screens/StaffAttendance';
import UsersManagement from './Apps/Screens/UsersManagement';
import Header from './Apps/css/header';
import MainDrawer from './Apps/Screens/MainDrawerContent';
import UserLandingPage from './Apps/Screens/UserLandingPage';
import SignOutPage from './Apps/Screens/SignOutPage';

const firebaseConfig = {
  apiKey: "AIzaSyAyzAXx7lXVKVNoSfKuzbz8cPx47tT-nXM",
  authDomain: "zi-goals.firebaseapp.com",
  projectId: "zi-goals",
  storageBucket: "zi-goals.appspot.com",
  messagingSenderId: "870416339071",
  appId: "1:870416339071:web:6b4f84985abefec96baf4a"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const Stack = createStackNavigator();

const AuthScreen = ({ navigation, email, setEmail, password, setPassword, isLogin, setIsLogin, handleAuthentication }) => {
  return (
    <ImageBackground
      source={require('./assets/zibotics/Background.svg')} // Use a PNG file if SVG handling is not set up
      style={styles.background}
      resizeMode="cover"
    >
      <View style={styles.container}>
        <View style={styles.authContainer}>
          <Text style={styles.title}>{isLogin ? 'Sign In' : 'Sign Up'}</Text>
          <TextInput
            style={styles.input}
            value={email}
            onChangeText={setEmail}
            placeholder="Email"
            placeholderTextColor="#545454"
            autoCapitalize="none"
          />
          <TextInput
            style={styles.input}
            value={password}
            onChangeText={setPassword}
            placeholder="Password"
            placeholderTextColor="#545454"
            secureTextEntry
          />
          <View style={styles.buttonContainer}>
            <Button title={isLogin ? 'Sign In' : 'Sign Up'} onPress={() => handleAuthentication(navigation)} color="#FF5757" />
          </View>
          <View style={styles.bottomContainer}>
            <Text style={styles.toggleText} onPress={() => setIsLogin(!isLogin)}>
              {isLogin ? 'Need an account? Sign Up' : 'Already have an account? Sign In'}
            </Text>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

const EnquiryScreen = () => {
  return (
    <View style={styles.enquiryContainer}>
      <Text style={styles.enquiryTitle}>Enquiry</Text>
      <Text style={styles.enquiryText}>Your email is not authorized as an admin. Please contact support for more information.</Text>
    </View>
  );
};

const App = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);
  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleAuthentication = async (navigation) => {
    try {
      let userCredential;
      if (isLogin) {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      } else {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      }

      await storeLoginInfoInFirestore(userCredential.user);
      await checkAdminAndNavigate(userCredential.user, navigation);
    } catch (error) {
      Alert.alert('Authentication Error', error.message);
    }
  };

  const storeLoginInfoInFirestore = async (user) => {
    const userRef = doc(firestore, 'users', user.uid);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      await updateDoc(userRef, {
        lastLogin: serverTimestamp(),
      });
    } else {
      await setDoc(userRef, {
        uid: user.uid,
        email: user.email,
        createdAt: serverTimestamp(),
        lastLogin: serverTimestamp(),
      });
    }
  };

  const checkAdminAndNavigate = async (user, navigation) => {
    // Check if the user is an admin
    const adminCollectionRef = collection(firestore, 'admins');
    const adminQuery = query(adminCollectionRef, where('admin_mail_id', '==', user.email));
    const adminSnapshot = await getDocs(adminQuery);
  
    if (!adminSnapshot.empty) {
      // User is an admin, navigate to the Main page
      navigation.navigate('Main');
    } else {
      // User is not an admin, check if they are listed in any admin's users array
      const addusersCollectionRef = collection(firestore, 'addusers');
      const adminDocsSnapshot = await getDocs(addusersCollectionRef);
      let userFound = false;
  
      console.log('Checking addusers collection for user:', user.email);
  
      adminDocsSnapshot.forEach(docSnap => {
        const data = docSnap.data();
        console.log('Document data:', data);
  
        if (data.users && Array.isArray(data.users)) {
          const usersList = data.users;
          console.log('Users list:', usersList);
  
          const userExists = usersList.some(u => u.email === user.email && !u.deleted);
          if (userExists) {
            userFound = true;
            navigation.navigate('UserLanding', { user });
          }
        } else {
          console.log('No users array found in document:', docSnap.id);
        }
      });
  
      if (!userFound) {
        console.log('User not found in any admin\'s users array. Navigating to Enquiry.');
        navigation.navigate('Enquiry');
      }
    }
  };

  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          header: () => <Header />,
          headerStyle: {
            backgroundColor: '#FF5757',
          },
          headerTintColor: 'white',
          headerTitleStyle: {
            fontWeight: 'bold',
          },
        }}
      >
        <Stack.Screen name="Auth">
          {(props) => (
            <AuthScreen
              {...props}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              isLogin={isLogin}
              setIsLogin={setIsLogin}
              handleAuthentication={(nav) => handleAuthentication(nav)}
            />
          )}
        </Stack.Screen>
        <Stack.Screen name="Main" component={MainDrawer} options={{ headerShown: false }} />
        <Stack.Screen name="UserLanding">
          {(props) => (
            <UserLandingPage
              {...props}
              user={user}
            />
          )}
        </Stack.Screen>
        <Stack.Screen name="Enquiry" component={EnquiryScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    resizeMode: 'cover',
    width: '100%',
    height: '100%',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  authContainer: {
    width: '80%',
    maxWidth: 400,
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 8,
    elevation: 3,
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
    textAlign: 'center',
    color: '#FF5757',
  },
  input: {
    height: 40,
    borderColor: '#ddd',
    borderWidth: 1,
    marginBottom: 16,
    padding: 8,
    borderRadius: 4,
    color: '#545454',
  },
  buttonContainer: {
    marginBottom: 16,
  },
  toggleText: {
    color: '#545454',
    textAlign: 'center',
  },
  bottomContainer: {
    marginTop: 20,
  },
  emailText: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
    color: '#545454',
  },
  enquiryContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  enquiryTitle: {
    fontSize: 24,
    marginBottom: 16,
    textAlign: 'center',
    color: '#FF5757',
  },
  enquiryText: {
    fontSize: 16,
    textAlign: 'center',
    color: '#545454',
  },
});

export default App;