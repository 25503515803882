import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, setDoc, getDoc, updateDoc, deleteDoc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyAyzAXx7lXVKVNoSfKuzbz8cPx47tT-nXM",
    authDomain: "zi-goals.firebaseapp.com",
    projectId: "zi-goals",
    storageBucket: "zi-goals.appspot.com",
    messagingSenderId: "870416339071",
    appId: "1:870416339071:web:6b4f84985abefec96baf4a"
};
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-y: auto; /* Enable vertical scrolling */
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  color: #333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #ff5757;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #e64a4a;
  }
`;

const SubscriptionPlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  max-height: 80vh; /* Set a maximum height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
`;

const SubscriptionBox = styled.div`
  flex: 1 1 100%;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SubscriptionBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const SubscriptionBoxActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const ToggleButton = styled.button`
  padding: 10px;
  background-color: #ff5757;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e64a4a;
  }
`;

const SubscriptionPlans = () => {
  const [plans, setPlans] = useState([]);
  const [planName, setPlanName] = useState('');
  const [duration, setDuration] = useState('');
  const [fees, setFees] = useState('');
  const [description, setDescription] = useState('');
  const [adminEmail, setAdminEmail] = useState(null);
  const [editPlanId, setEditPlanId] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setAdminEmail(currentUser.email);
        fetchPlans(currentUser.email);
      } else {
        setAdminEmail(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchPlans = async (adminEmail) => {
    try {
      const docRef = doc(firestore, 'subscriptions', adminEmail);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPlans(docSnap.data().plans || []);
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  const handleAddOrUpdatePlan = async (e) => {
    e.preventDefault();
    try {
      if (!adminEmail) return;

      const newPlan = {
        planName,
        duration,
        fees,
        description,
        insertedBy: adminEmail,
        updatedAt: Timestamp.now(),
      };

      const docRef = doc(firestore, 'subscriptions', adminEmail);
      const docSnap = await getDoc(docRef);

      let updatedPlans = [];
      if (docSnap.exists()) {
        if (editPlanId !== null) {
          updatedPlans = docSnap.data().plans.map((plan, index) =>
            index === editPlanId ? newPlan : plan
          );
        } else {
          updatedPlans = [...docSnap.data().plans, newPlan];
        }
      } else {
        updatedPlans = [newPlan];
      }

      await setDoc(docRef, { plans: updatedPlans });

      setPlanName('');
      setDuration('');
      setFees('');
      setDescription('');
      setPlans(updatedPlans);
      setEditPlanId(null);
      setShowForm(false);
    } catch (error) {
      console.error('Error adding/updating plan:', error);
    }
  };

  const handleEditPlan = (plan, index) => {
    setPlanName(plan.planName);
    setDuration(plan.duration);
    setFees(plan.fees);
    setDescription(plan.description);
    setEditPlanId(index);
    setShowForm(true);
  };

  const handleDeletePlan = async (index) => {
    try {
      if (!adminEmail) return;

      const docRef = doc(firestore, 'subscriptions', adminEmail);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const updatedPlans = docSnap.data().plans.filter((_, i) => i !== index);
        await setDoc(docRef, { plans: updatedPlans });
        setPlans(updatedPlans);
      }
    } catch (error) {
      console.error('Error deleting plan:', error);
    }
  };

  return (
    <Container>
      <Header>
        <Title>Subscription Plans</Title>
        <ToggleButton onClick={() => setShowForm(!showForm)}>
          {showForm ? 'Hide Form' : 'Add Plan'}
        </ToggleButton>
      </Header>
      {showForm && (
        <Form onSubmit={handleAddOrUpdatePlan}>
          <Input
            type="text"
            placeholder="Plan Name"
            value={planName}
            onChange={(e) => setPlanName(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Duration (e.g., 1 month, 3 months, etc.)"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            required
          />
          <Input
            type="number"
            placeholder="Fees"
            value={fees}
            onChange={(e) => setFees(e.target.value)}
            required
          />
          <TextArea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <Button type="submit">{editPlanId !== null ? 'Update Plan' : 'Add Plan'}</Button>
        </Form>
      )}
      <SubscriptionPlansContainer>
        {plans.map((plan, index) => (
          <SubscriptionBox key={index}>
            <SubscriptionBoxHeader>
              <h3>{plan.planName}</h3>
            </SubscriptionBoxHeader>
            <p>Duration: {plan.duration}</p>
            <p>Fees: {plan.fees}</p>
            <p>Description: {plan.description}</p>
            <p>Added by: {plan.insertedBy}</p>
            <p>Updated at: {plan.updatedAt.toDate().toLocaleString()}</p>
            <SubscriptionBoxActions>
              <Button onClick={() => handleEditPlan(plan, index)}>Edit</Button>
              <Button onClick={() => handleDeletePlan(index)}>Delete</Button>
            </SubscriptionBoxActions>
          </SubscriptionBox>
        ))}
      </SubscriptionPlansContainer>
    </Container>
  );
};

export default SubscriptionPlans;