import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, setDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAyzAXx7lXVKVNoSfKuzbz8cPx47tT-nXM",
    authDomain: "zi-goals.firebaseapp.com",
    projectId: "zi-goals",
    storageBucket: "zi-goals.appspot.com",
    messagingSenderId: "870416339071",
    appId: "1:870416339071:web:6b4f84985abefec96baf4a"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

// Styled Components
const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  color: #333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #ff5757;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #e64a4a;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const FilterButton = styled.button`
  padding: 10px;
  margin: 0 5px;
  background-color: #ff5757;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &.active {
    background-color: #e64a4a;
  }

  &:hover {
    background-color: #e64a4a;
  }
`;

const UsersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  max-height: 80vh;
  overflow-y: auto;
`;

const UserBox = styled.div`
  flex: 1 1 100%;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const UserBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const UserBoxActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

// UsersManagement Component
const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [contactNo, setContactNo] = useState('');
  const [name, setName] = useState('');
  const [subscription, setSubscription] = useState('');
  const [classType, setClassType] = useState('');
  const [paidAmount, setPaidAmount] = useState('');
  const [paidDate, setPaidDate] = useState('');
  const [subscriptionEndDate, setSubscriptionEndDate] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [adminEmail, setAdminEmail] = useState(null);
  const [editUserId, setEditUserId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setAdminEmail(currentUser.email);
        fetchUsers(currentUser.email);
      } else {
        setAdminEmail(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchUsers = async (adminEmail) => {
    try {
      const docRef = doc(firestore, 'addusers', adminEmail);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUsers(docSnap.data().users || []);
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleAddOrUpdateUser = async (e) => {
    e.preventDefault();
    try {
      if (!adminEmail) return;

      const newUser = {
        contactNo,
        name,
        subscription,
        classType,
        paidAmount,
        paidDate,
        subscriptionEndDate,
        email,
        gender,
        insertedBy: adminEmail,
        updatedAt: Timestamp.now(),
        deleted: false,
      };

      const docRef = doc(firestore, 'addusers', adminEmail);
      const docSnap = await getDoc(docRef);

      let updatedUsers = [];
      if (docSnap.exists()) {
        if (editUserId !== null) {
          updatedUsers = docSnap.data().users.map((user, index) =>
            index === editUserId ? newUser : user
          );
        } else {
          updatedUsers = [...docSnap.data().users, newUser];
        }
      } else {
        updatedUsers = [newUser];
      }

      await setDoc(docRef, { users: updatedUsers });

      setContactNo('');
      setName('');
      setSubscription('');
      setClassType('');
      setPaidAmount('');
      setPaidDate('');
      setSubscriptionEndDate('');
      setEmail('');
      setGender('');
      setUsers(updatedUsers);
      setEditUserId(null);
      setShowForm(false);
    } catch (error) {
      console.error('Error adding/updating user:', error);
    }
  };

  const handleEditUser = (user, index) => {
    setContactNo(user.contactNo);
    setName(user.name);
    setSubscription(user.subscription);
    setClassType(user.classType);
    setPaidAmount(user.paidAmount);
    setPaidDate(user.paidDate);
    setSubscriptionEndDate(user.subscriptionEndDate);
    setEmail(user.email);
    setGender(user.gender);
    setEditUserId(index);
    setShowForm(true);
  };

  const handleDeleteUser = async (index) => {
    try {
      if (!adminEmail) return;

      const docRef = doc(firestore, 'addusers', adminEmail);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const updatedUsers = docSnap.data().users.map((user, i) => 
          i === index ? { ...user, deleted: true } : user
        );
        await setDoc(docRef, { users: updatedUsers });
        setUsers(updatedUsers);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleFilter = (filterType) => {
    setFilter(filterType);
  };

  const filteredUsers = users.filter((user) => {
    if (filter === 'all') return !user.deleted;
    if (filter === 'active') return !user.deleted && new Date(user.subscriptionEndDate) > new Date();
    if (filter === 'followup') return !user.deleted && new Date(user.subscriptionEndDate) <= new Date() && new Date(user.subscriptionEndDate) > new Date(new Date().setDate(new Date().getDate() - 7));
    if (filter === 'subscriptionEnd') return !user.deleted && new Date(user.subscriptionEndDate) <= new Date(new Date().setDate(new Date().getDate() - 7));
    return true;
  });

  return (
    <Container>
      <Header>
        <Title>Users Management</Title>
        <Button onClick={() => setShowForm(!showForm)}>
          {showForm ? 'Hide Form' : 'Add User'}
        </Button>
      </Header>
      {showForm && (
        <Form onSubmit={handleAddOrUpdateUser}>
          <Input
            type="text"
            placeholder="Contact No"
            value={contactNo}
            onChange={(e) => setContactNo(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Subscription"
            value={subscription}
            onChange={(e) => setSubscription(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Class"
            value={classType}
            onChange={(e) => setClassType(e.target.value)}
            required
          />
          <Input
            type="number"
            placeholder="Paid Amount"
            value={paidAmount}
            onChange={(e) => setPaidAmount(e.target.value)}
            required
          />
          <Input
            type="date"
            placeholder="Paid Date"
            value={paidDate}
            onChange={(e) => setPaidDate(e.target.value)}
            required
          />
          <Input
            type="date"
            placeholder="Subscription End Date"
            value={subscriptionEndDate}
            onChange={(e) => setSubscriptionEndDate(e.target.value)}
            required
          />
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
          />
          <Button type="submit">
            {editUserId !== null ? 'Update User' : 'Add User'}
          </Button>
        </Form>
      )}
      <FilterContainer>
        <FilterButton
          className={filter === 'all' ? 'active' : ''}
          onClick={() => handleFilter('all')}
        >
          All Users
        </FilterButton>
        <FilterButton
          className={filter === 'active' ? 'active' : ''}
          onClick={() => handleFilter('active')}
        >
          Active Users
        </FilterButton>
        <FilterButton
          className={filter === 'followup' ? 'active' : ''}
          onClick={() => handleFilter('followup')}
        >
          Follow-Up Users
        </FilterButton>
        <FilterButton
          className={filter === 'subscriptionEnd' ? 'active' : ''}
          onClick={() => handleFilter('subscriptionEnd')}
        >
          Subscription End Users
        </FilterButton>
      </FilterContainer>
      <UsersContainer>
        {filteredUsers.map((user, index) => (
          <UserBox key={index}>
            <UserBoxHeader>
              <div>{user.name}</div>
              <UserBoxActions>
                <Button onClick={() => handleEditUser(user, index)}>Edit</Button>
                <Button onClick={() => handleDeleteUser(index)}>Delete</Button>
              </UserBoxActions>
            </UserBoxHeader>
            <div>Contact No: {user.contactNo}</div>
            <div>Subscription: {user.subscription}</div>
            <div>Class: {user.classType}</div>
            <div>Paid Amount: {user.paidAmount}</div>
            <div>Paid Date: {user.paidDate}</div>
            <div>Subscription End Date: {user.subscriptionEndDate}</div>
            <div>Email: {user.email}</div>
            <div>Gender: {user.gender}</div>
          </UserBox>
        ))}
      </UsersContainer>
    </Container>
  );
};

export default UsersManagement;