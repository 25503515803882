import { useNavigation } from '@react-navigation/native';
import { getAuth, signOut } from 'firebase/auth';

const useSignOut = () => {
  const navigation = useNavigation();
  const auth = getAuth();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigation.navigate('Auth'); // Navigate to Auth screen after sign-out
    } catch (error) {
      console.error('Sign-out error:', error.message);
    }
  };

  return { handleSignOut };
};

export default useSignOut;
