import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

const AdminPage = ({ navigation }) => {
    const handleNavigation = () => {
      navigation.navigate('AddUser');
    };
  
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.headerText}>Admin Dashboard</Text>
        </View>
        <View style={styles.content}>
          <TouchableOpacity style={styles.button} onPress={handleNavigation}>
            <Icon name="user-plus" size={30} color="#fff" />
            <Text style={styles.buttonText}>Add User</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.button}>
            <Icon name="file-text" size={30} color="#fff" />
            <Text style={styles.buttonText}>Expense Report</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.button}>
            <Icon name="bar-chart" size={30} color="#fff" />
            <Text style={styles.buttonText}>Summary Report</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>© 2024 Your Company</Text>
        </View>
      </View>
    );
  };
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f0f0f0',
  },
  header: {
    height: 60,
    backgroundColor: '#3498db',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 'bold',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: '#3498db',
    padding: 20,
    margin: 10,
    borderRadius: 10,
    alignItems: 'center',
    flexDirection: 'row',
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    marginLeft: 10,
  },
  footer: {
    height: 40,
    backgroundColor: '#3498db',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
  },
});

export default AdminPage;