import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, ScrollView, FlatList, Animated, Easing } from 'react-native';
import { getFirestore, collection, addDoc, query, where, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import Icon from 'react-native-vector-icons/FontAwesome';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAyzAXx7lXVKVNoSfKuzbz8cPx47tT-nXM",
  authDomain: "zi-goals.firebaseapp.com",
  projectId: "zi-goals",
  storageBucket: "zi-goals.appspot.com",
  messagingSenderId: "870416339071",
  appId: "1:870416339071:web:6b4f84985abefec96baf4a"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

const getCurrentDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  return `${day}/${month}/${year}`;
};

const formatDateForStorage = (dateStr) => {
  const [day, month, year] = dateStr.split('/');
  return `${year}/${month}/${day}`; // yyyy-mm-dd format for storage
};

const formatDateForDisplay = (dateStr) => {
  const [year, month, day] = dateStr.split('/');
  return `${day}/${month}/${year}`; // dd/mm/yyyy format for display
};

const isValidDate = (dateStr) => {
  const [day, month, year] = dateStr.split('/');
  const dayNum = parseInt(day, 10);
  const monthNum = parseInt(month, 10);
  const yearNum = parseInt(year, 10);

  const isValidDay = dayNum >= 1 && dayNum <= 31;
  const isValidMonth = monthNum >= 1 && monthNum <= 12;
  const isValidYear = yearNum.toString().length === 4;

  return isValidDay && isValidMonth && isValidYear;
};

const ExpensePage = () => {
  const [showForm, setShowForm] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [todayExpenses, setTodayExpenses] = useState([]);
  const [viewAll, setViewAll] = useState(false);
  const [formData, setFormData] = useState({
    date: getCurrentDate(),
    name: '',
    description: '',
    paymentStatus: 'Paid',
    amountPaid: '',
    paymentMode: 'Cash',
  });
  const [showPaymentStatusOptions, setShowPaymentStatusOptions] = useState(false);
  const [showPaymentModeOptions, setShowPaymentModeOptions] = useState(false);
  const [selectedExpenseId, setSelectedExpenseId] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    amountPaid: '',
    date: '',
  });
  const formAnimation = useState(new Animated.Value(0))[0];

  const currentUser = auth.currentUser;

  useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = async () => {
    if (!currentUser) return;

    try {
      const expensesRef = collection(firestore, "expenses");
      const q = query(expensesRef, where("insertedBy", "==", currentUser.email));
      const querySnapshot = await getDocs(q);

      const expensesData = [];
      querySnapshot.forEach((doc) => {
        const expenseData = { id: doc.id, ...doc.data() };
        expenseData.date = formatDateForDisplay(expenseData.date); // Format date for display
        expensesData.push(expenseData);
      });

      setExpenses(expensesData);
      filterTodayExpenses(expensesData);
    } catch (e) {
      console.error("Error fetching documents: ", e);
    }
  };

  const filterTodayExpenses = (allExpenses) => {
    const today = getCurrentDate();
    const todayExpenses = allExpenses.filter(expense => expense.date === today);
    setTodayExpenses(todayExpenses);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: '', amountPaid: '', date: '' };

    // Validate Name
    if (!/^[a-zA-Z\s]*$/.test(formData.name)) {
      newErrors.name = 'Name should contain only letters and spaces.';
      isValid = false;
    }

    // Validate Amount Paid
    if (!/^\d+(\.\d{1,2})?$/.test(formData.amountPaid)) {
      newErrors.amountPaid = 'Amount Paid should be a number.';
      isValid = false;
    }

    // Validate Date
    if (!isValidDate(formData.date)) {
      newErrors.date = 'Date should be in dd/mm/yyyy format with valid day, month, and year.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleAddExpense = () => {
    setShowForm(true);
    setSelectedExpenseId('');
    setFormData({
      date: getCurrentDate(),
      name: '',
      description: '',
      paymentStatus: 'Paid',
      amountPaid: '',
      paymentMode: 'Cash',
    });
    setErrors({ name: '', amountPaid: '', date: '' });
    Animated.timing(formAnimation, {
      toValue: 1,
      duration: 500,
      easing: Easing.inOut(Easing.ease),
      useNativeDriver: true,
    }).start();
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      let docRef;
      const formattedDate = formatDateForStorage(formData.date);
      const expenseData = {
        ...formData,
        date: formattedDate,
        amountPaid: parseFloat(formData.amountPaid),
        insertedBy: currentUser.email,
      };

      if (selectedExpenseId) {
        // Update existing expense
        const expenseRef = doc(firestore, "expenses", selectedExpenseId);
        await updateDoc(expenseRef, expenseData);
        docRef = expenseRef;
      } else {
        // Add new expense
        docRef = await addDoc(collection(firestore, "expenses"), expenseData);
      }

      setShowForm(false);
      fetchExpenses();
    } catch (e) {
      console.error("Error adding/updating document: ", e);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(firestore, "expenses", id));
      fetchExpenses();
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  };

  const handleEdit = (expense) => {
    setSelectedExpenseId(expense.id);
    setFormData({
      date: formatDateForDisplay(expense.date), // Format date for display in form
      name: expense.name,
      description: expense.description,
      paymentStatus: expense.paymentStatus,
      amountPaid: String(expense.amountPaid),
      paymentMode: expense.paymentMode,
    });
    setShowForm(true);
  };

  const renderExpenseItem = ({ item }) => (
    <View style={styles.expenseRow}>
      <Text style={styles.expenseText}>{item.date}</Text>
      <Text style={styles.expenseText}>{item.name}</Text>
      <Text style={styles.expenseText}>{item.description}</Text>
      <Text style={styles.expenseText}>{item.paymentStatus}</Text>
      <Text style={styles.expenseText}>{item.amountPaid}</Text>
      <Text style={styles.expenseText}>{item.paymentMode}</Text>
      <View style={styles.actions}>
        <TouchableOpacity onPress={() => handleEdit(item)}>
          <Icon name="edit" size={20} color="red" />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleDelete(item.id)}>
          <Icon name="trash" size={20} color="red" />
        </TouchableOpacity>
      </View>
    </View>
  );

  return (
    <View style={styles.container}>
      <Text style={styles.heading}>Today's Expenses</Text>
      {todayExpenses.length > 0 ? (
        <FlatList
          data={todayExpenses}
          renderItem={renderExpenseItem}
          keyExtractor={(item) => item.id}
          // ListHeaderComponent={
          //   <View style={styles.headerRow}>
          //     <Text style={styles.headerText}>Date</Text>
          //     <Text style={styles.headerText}>Name</Text>
          //     <Text style={styles.headerText}>Description</Text>
          //     <Text style={styles.headerText}>Status</Text>
          //     <Text style={styles.headerText}>Amount Paid</Text>
          //     <Text style={styles.headerText}>Payment Mode</Text>
          //     <Text style={styles.headerText}>Actions</Text>
          //   </View>
          // }
        />
      ) : (
        <Text>No expenses recorded for today. 😊</Text>
      )}

      <TouchableOpacity
        style={styles.viewExpensesButton}
        onPress={() => setViewAll(!viewAll)}
      >
        <Text style={styles.viewExpensesButtonText}>
          {viewAll ? 'Today Expenses' : 'View All Expenses'}
        </Text>
      </TouchableOpacity>

      {viewAll && (
        <FlatList
          data={expenses}
          renderItem={renderExpenseItem}
          keyExtractor={(item) => item.id}
          ListHeaderComponent={
            <View style={styles.headerRow}>
              <Text style={styles.headerText}>Date</Text>
              <Text style={styles.headerText}>Name</Text>
              <Text style={styles.headerText}>Description</Text>
              <Text style={styles.headerText}>Status</Text>
              <Text style={styles.headerText}>Amount Paid</Text>
              <Text style={styles.headerText}>Payment Mode</Text>
              <Text style={styles.headerText}>Actions</Text>
            </View>
          }
        />
      )}

      <TouchableOpacity style={styles.addButton} onPress={handleAddExpense}>
        <Icon name="plus" size={30} color="#fff" />
      </TouchableOpacity>

      {showForm && (
        <Animated.View style={[styles.formContainer, { opacity: formAnimation }]}>
          <View style={styles.form}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setShowForm(false)}
            >
              <Icon name="times" size={20} color="red" />
            </TouchableOpacity>
            <TextInput
              style={styles.input}
              placeholder="Name"
              value={formData.name}
              onChangeText={(text) => setFormData({ ...formData, name: text })}
            />
            {errors.name ? <Text style={styles.errorText}>{errors.name}</Text> : null}
            <TextInput
              style={styles.input}
              placeholder="Description"
              value={formData.description}
              onChangeText={(text) => setFormData({ ...formData, description: text })}
            />
            <TouchableOpacity
              style={styles.dropdown}
              onPress={() => setShowPaymentStatusOptions(!showPaymentStatusOptions)}
            >
              <Text style={styles.dropdownText}>{formData.paymentStatus}</Text>
              <Icon name="caret-down" size={20} color="black" />
            </TouchableOpacity>
            {showPaymentStatusOptions && (
              <View style={styles.dropdownOptions}>
                <TouchableOpacity
                  style={styles.dropdownOption}
                  onPress={() => {
                    setFormData({ ...formData, paymentStatus: 'Paid' });
                    setShowPaymentStatusOptions(false);
                  }}
                >
                  <Text>Paid</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.dropdownOption}
                  onPress={() => {
                    setFormData({ ...formData, paymentStatus: 'Unpaid' });
                    setShowPaymentStatusOptions(false);
                  }}
                >
                  <Text>Unpaid</Text>
                </TouchableOpacity>
              </View>
            )}
            <TextInput
              style={styles.input}
              placeholder="Amount Paid"
              value={formData.amountPaid}
              onChangeText={(text) => setFormData({ ...formData, amountPaid: text })}
              keyboardType="numeric"
            />
            {errors.amountPaid ? <Text style={styles.errorText}>{errors.amountPaid}</Text> : null}
            <TouchableOpacity
              style={styles.dropdown}
              onPress={() => setShowPaymentModeOptions(!showPaymentModeOptions)}
            >
              <Text style={styles.dropdownText}>{formData.paymentMode}</Text>
              <Icon name="caret-down" size={20} color="black" />
            </TouchableOpacity>
            {showPaymentModeOptions && (
              <View style={styles.dropdownOptions}>
                <TouchableOpacity
                  style={styles.dropdownOption}
                  onPress={() => {
                    setFormData({ ...formData, paymentMode: 'Cash' });
                    setShowPaymentModeOptions(false);
                  }}
                >
                  <Text>Cash</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.dropdownOption}
                  onPress={() => {
                    setFormData({ ...formData, paymentMode: 'Card' });
                    setShowPaymentModeOptions(false);
                  }}
                >
                  <Text>Card</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.dropdownOption}
                  onPress={() => {
                    setFormData({ ...formData, paymentMode: 'GPay' });
                    setShowPaymentModeOptions(false);
                  }}
                >
                  <Text>GPay</Text>
                </TouchableOpacity>
              </View>
            )}
            <TextInput
              style={styles.input}
              placeholder="Date (dd/mm/yyyy)"
              value={formData.date}
              onChangeText={(text) => setFormData({ ...formData, date: text })}
            />
            {errors.date ? <Text style={styles.errorText}>{errors.date}</Text> : null}
            <TouchableOpacity style={styles.submitButton} onPress={handleSubmit}>
              <Text style={styles.submitButtonText}>Submit</Text>
            </TouchableOpacity>
          </View>
        </Animated.View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: '#fff',
  },
  heading: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  expenseRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent:'center',
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  expenseText: {
    flex: 1,
    textAlign: 'center',
  },
  actions: {
    flex:1,
    flexDirection: 'row',
    justifyContent: 'center',
    width: 80,
  },
  viewExpensesButton: {
    backgroundColor: '#FF5757',
    padding: 10,
    borderRadius: 5,
    marginTop: 16,
    alignSelf: 'center',
  },
  viewExpensesButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },

  addButton: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    backgroundColor: '#FF5757', // Red background
    width: 60,
    height: 60,
    borderRadius: 30, // Circular shape
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5, // Optional: Adds shadow for better visibility
  },
  formContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  form: {
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 10,
    width: '80%',
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 8,
    borderRadius: 5,
    marginBottom: 16,
  },
  dropdown: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 8,
    borderRadius: 5,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dropdownText: {
    flex: 1,
  },
  dropdownOptions: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    backgroundColor: '#fff',
    marginTop: -16,
    marginBottom: 16,
  },
  dropdownOption: {
    padding: 8,
  },
  submitButton: {
    backgroundColor: '#FF5757',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
  },
  submitButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  errorText: {
    color: 'red',
    marginBottom: 16,
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent:'center',
    paddingVertical: 8,
    borderBottomWidth: 2,
    borderBottomColor: '#000',
  },
  headerText: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

export default ExpensePage;
