import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, Image, TouchableOpacity, Modal, Picker, Button, StyleSheet } from 'react-native';
import { getFirestore, collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { useNavigation } from '@react-navigation/native';
import Svg, { Path } from 'react-native-svg';
import { initializeApp } from '@firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from '@firebase/auth';
import RazorpayCheckout from 'react-native-razorpay';
// Ensure you have the correct path

const firebaseConfig = {
    apiKey: "AIzaSyAyzAXx7lXVKVNoSfKuzbz8cPx47tT-nXM",
    authDomain: "zi-goals.firebaseapp.com",
    projectId: "zi-goals",
    storageBucket: "zi-goals.appspot.com",
    messagingSenderId: "870416339071",
    appId: "1:870416339071:web:6b4f84985abefec96baf4a"
  };
  
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  
  const UserLandingPage = ({ route }) => {
    const { user } = route.params;
    const [userData, setUserData] = useState([]);
    const [adminData, setAdminData] = useState({});
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userName, setUserName] = useState('');
    const navigation = useNavigation();
  
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const addUsersCollection = collection(firestore, 'addusers');
          const querySnapshot = await getDocs(addUsersCollection);
  
          let userList = [];
          let fetchedUserName = '';
  
          for (const doc of querySnapshot.docs) {
            const adminEmail = doc.id;
            const adminData = doc.data();
  
            if (adminData.users && Array.isArray(adminData.users)) {
              const matchingUser = adminData.users.find(u => u.email === user.email);
              if (matchingUser) {
                userList.push({ ...matchingUser, adminEmail });
                fetchedUserName = matchingUser.name; // Capture the user's name
              }
            }
          }
  
          setUserData(userList);
          setUserName(fetchedUserName); // Set the user's name state
  
          let adminDetails = {};
          for (const user of userList) {
            const adminQuery = query(
              collection(firestore, 'admins'),
              where('admin_mail_id', '==', user.adminEmail)
            );
            const adminSnapshot = await getDocs(adminQuery);
  
            if (!adminSnapshot.empty) {
              adminSnapshot.forEach(adminDoc => {
                adminDetails[user.adminEmail] = adminDoc.data();
              });
            }
          }
  
          setAdminData(adminDetails);
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      };
  
      fetchUserData();
    }, [firestore, user.email]);
  
    useEffect(() => {
      if (selectedAdmin) {
        fetchSubscriptionPlans(selectedAdmin);
      }
    }, [selectedAdmin]);
  
    const fetchSubscriptionPlans = async (adminEmail) => {
      try {
        console.log('Fetching subscription plans for:', adminEmail); // Debug log
        const subscriptionsDocRef = doc(firestore, 'subscriptions', adminEmail);
        const subscriptionsDoc = await getDoc(subscriptionsDocRef);
  
        if (subscriptionsDoc.exists()) {
          const subscriptionData = subscriptionsDoc.data();
          const plans = subscriptionData.plans || [];
          setSubscriptionPlans(plans);
          console.log('Fetched plans:', plans); // Debug log
        } else {
          console.log('No subscription data found for admin:', adminEmail);
          setSubscriptionPlans([]); // Clear plans if none found
        }
      } catch (error) {
        console.error("Error fetching subscription plans: ", error);
      }
    };
  
    const handleRenewSubscription = (adminEmail) => {
      setSelectedAdmin(adminEmail);
      setIsModalVisible(true);
    };
  
    const handlePayment = async () => {
      if (!selectedPlan) {
        console.log("Please select a plan.");
        return;
      }
  
      try {
        const response = await fetch('https://razorpay.me/@ziboticsprivatelimited', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ amount: selectedPlan.amount }),
        });
        const data = await response.json();
  
        const options = {
          description: 'Purchase Description',
          image: 'https://your-logo-url',
          currency: 'INR',
          key: 'rzp_test_adn0Zqj6c9VPS7',
          amount: data.amount,
          order_id: data.orderId,
          name: 'Subscription Renewal',
          prefill: {
            email: user.email,
            contact: user.contactNo,
          },
        };
  
        RazorpayCheckout.open(options).then(async (data) => {
          console.log('Payment success:', data);
  
          // Add payment information to Firestore
          const paymentInfo = {
            name: userName,
            emailId: user.email,
            transactionId: data.razorpay_payment_id,
            paidDate: new Date().toISOString(),
            chosenPlan: selectedPlan.planName,
          };
  
          try {
            const paymentInfoRef = doc(firestore, 'paymentInformation', user.email); // Store under the user's email
            await setDoc(paymentInfoRef, paymentInfo);
            console.log('Payment information saved to Firestore');
          } catch (error) {
            console.error('Error saving payment information:', error);
          }
  
          setIsModalVisible(false);
        }).catch((error) => {
          console.error('Payment error:', error);
        });
      } catch (error) {
        console.error("Error creating order: ", error);
      }
    };
  
    const handleViewSubscription = (adminEmail) => {
      // Implement view subscription logic here
    };
  
    const handleReview = (adminEmail) => {
      // Implement review logic here
    };
  
    return (
      <View style={styles.container}>
        <Text style={styles.header}>Welcome, {userName}</Text>
        <FlatList
          data={userData}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => {
            const admin = adminData[item.adminEmail];
            const isRenewEnabled = new Date(item.subscriptionEndDate) <= new Date(new Date().setDate(new Date().getDate() + 7));
            
            return (
              <View style={styles.adminBox}>
                {admin && (
                  <>
                    <Image source={{ uri: admin.adminlogo }} style={styles.adminLogo} />
                    <Text style={styles.adminName}>{admin.admin_name}</Text>
                  </>
                )}
                <Text style={styles.userInfo}>Name: {item.name}</Text>
                <Text style={styles.userInfo}>Class: {item.classType}</Text>
                <Text style={styles.userInfo}>Contact: {item.contactNo}</Text>
                <Text style={styles.userInfo}>Subscription: {item.subscription}</Text>
                <Text style={styles.userInfo}>Subscription End Date: {item.subscriptionEndDate}</Text>
                <View style={styles.buttonContainer}>
                  <TouchableOpacity style={styles.smallButton} onPress={() => handleViewSubscription(item.adminEmail)}>
                    <Text style={styles.smallButtonText}>View Subscription</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.smallButton} onPress={() => handleReview(item.adminEmail)}>
                    <Text style={styles.smallButtonText}>Review</Text>
                  </TouchableOpacity>
                  {isRenewEnabled && (
                    <TouchableOpacity style={styles.smallButton} onPress={() => handleRenewSubscription(item.adminEmail)}>
                      <Text style={styles.smallButtonText}>Renew Subscription</Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            );
          }}
        />
        <Button title="Back to Login" onPress={() => navigation.navigate('Auth')} />
  
        {/* Modal for subscription renewal */}
        <Modal
          visible={isModalVisible}
          transparent={true}
          animationType="slide"
          onRequestClose={() => setIsModalVisible(false)}
        >
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Select Subscription Plan</Text>
              <Picker
                selectedValue={selectedPlan}
                onValueChange={(itemValue) => {
                  console.log('Selected plan:', itemValue); // Debug log
                  setSelectedPlan(itemValue);
                }}
                style={styles.picker}
              >
                {subscriptionPlans.map((plan, index) => (
                  <Picker.Item key={index} label={`${plan.planName} - ${plan.fees} INR`} value={plan.fees} />
                ))}
              </Picker>
              <TouchableOpacity style={styles.button} onPress={handlePayment}>
                <Text style={styles.buttonText}>Pay Now</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={() => setIsModalVisible(false)}>
                <Text style={styles.buttonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>
    );
  };
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      padding: 10,
      backgroundColor: '#f0f0f0'
    },
    header: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 10
    },
    adminBox: {
      padding: 10,
      marginBottom: 10,
      backgroundColor: '#fff',
      borderRadius: 5,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 5
    },
    adminLogo: {
      width: 50,
      height: 50,
      borderRadius: 25,
      marginBottom: 10
    },
    adminName: {
      fontSize: 16,
      fontWeight: 'bold'
    },
    userInfo: {
      fontSize: 14,
      marginVertical: 2
    },
    buttonContainer: {
      marginTop: 10
    },
    smallButton: {
      backgroundColor: '#FF5757',
      padding: 10,
      borderRadius: 5,
      marginBottom: 5
    },
    smallButtonText: {
      color: '#fff',
      textAlign: 'center'
    },
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)'
    },
    modalContent: {
      width: '80%',
      backgroundColor: '#fff',
      borderRadius: 10,
      padding: 20,
      alignItems: 'center'
    },
    modalTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 10
    },
    picker: {
      width: '100%',
      height: 50,
      marginBottom: 20
    },
    button: {
      backgroundColor: '#FF5757',
      padding: 10,
      borderRadius: 5,
      marginBottom: 10
    },
    buttonText: {
      color: '#fff',
      textAlign: 'center'
    }
  });
  
  export default UserLandingPage;