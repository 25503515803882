import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, getDoc, setDoc, updateDoc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAyzAXx7lXVKVNoSfKuzbz8cPx47tT-nXM",
    authDomain: "zi-goals.firebaseapp.com",
    projectId: "zi-goals",
    storageBucket: "zi-goals.appspot.com",
    messagingSenderId: "870416339071",
    appId: "1:870416339071:web:6b4f84985abefec96baf4a"
};
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  color: #333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #ff5757;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #e64a4a;
  }
`;

const ToggleButton = styled.button`
  padding: 10px;
  background-color: #ff5757;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e64a4a;
  }
`;

const StaffContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const StaffBox = styled.div`
  flex: 1 1 250px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StaffBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AttendanceFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const AttendanceTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const RadioButton = styled.input`
  margin-right: 5px;
`;

const Dropdown = styled.select`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const ViewDetailsForm = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
`;

const StaffAttendance = () => {
  const [staff, setStaff] = useState([]);
  const [staffName, setStaffName] = useState('');
  const [adminEmail, setAdminEmail] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [attendance, setAttendance] = useState({});
  const [selectedDate, setSelectedDate] = useState('');
  const [updateCount, setUpdateCount] = useState(0);
  const [selectedStaff, setSelectedStaff] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [detailedAttendance, setDetailedAttendance] = useState({});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setAdminEmail(currentUser.email);
        fetchStaff(currentUser.email);
      } else {
        setAdminEmail(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchStaff = async (adminEmail) => {
    try {
      const docRef = doc(firestore, 'staff', adminEmail);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setStaff(docSnap.data().staff || []);
        setSelectedStaff(docSnap.data().staff?.[0]?.name || '');
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching staff:', error);
    }
  };

  const handleAddStaff = async (e) => {
    e.preventDefault();
    try {
      if (!adminEmail) return;

      const newStaff = {
        name: staffName,
        attendance: {},
      };

      const docRef = doc(firestore, 'staff', adminEmail);
      const docSnap = await getDoc(docRef);

      let updatedStaff = [];
      if (docSnap.exists()) {
        updatedStaff = [...docSnap.data().staff, newStaff];
      } else {
        updatedStaff = [newStaff];
      }

      await setDoc(docRef, { staff: updatedStaff });

      setStaffName('');
      setStaff(updatedStaff);
      setShowForm(false);
    } catch (error) {
      console.error('Error adding staff:', error);
    }
  };

  const handleAttendanceChange = (index, date, status) => {
    const newAttendance = { ...attendance };
    if (!newAttendance[index]) newAttendance[index] = {};
    newAttendance[index][date] = status;
    setAttendance(newAttendance);
    setUpdateCount(updateCount + 1);
  };

  const handleSaveAttendance = async () => {
    try {
      if (!adminEmail) return;

      const docRef = doc(firestore, 'staff', adminEmail);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const updatedStaff = docSnap.data().staff.map((member, index) => {
          if (attendance[index]) {
            return { ...member, attendance: { ...member.attendance, ...attendance[index] } };
          }
          return member;
        });

        await setDoc(docRef, { staff: updatedStaff });
        setStaff(updatedStaff);
        setAttendance({});
      }
    } catch (error) {
      console.error('Error saving attendance:', error);
    }
  };

  const getDaysPresent = (attendance) => {
    const currentMonth = new Date().getMonth();
    return Object.values(attendance).reduce((count, status) => {
      const attendanceDate = new Date(status);
      if (attendanceDate.getMonth() === currentMonth && status === 'P') {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const handleViewDetails = async () => {
    try {
      if (!adminEmail || !selectedStaff || !fromDate || !toDate) return;

      const docRef = doc(firestore, 'staff', adminEmail);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const staffList = docSnap.data().staff || [];
        const staffMember = staffList.find(member => member.name === selectedStaff);

        if (staffMember) {
          const detailedData = {};
          for (const [date, status] of Object.entries(staffMember.attendance)) {
            const attendanceDate = new Date(date);
            const startDate = new Date(fromDate);
            const endDate = new Date(toDate);

            if (attendanceDate >= startDate && attendanceDate <= endDate) {
              detailedData[date] = status;
            }
          }
          setDetailedAttendance(detailedData);
        }
      }
    } catch (error) {
      console.error('Error fetching detailed attendance:', error);
    }
  };

  return (
    <Container>
      <Header>
        <Title>Staff Attendance</Title>
        <div>
          <ToggleButton onClick={() => setShowForm(!showForm)}>
            {showForm ? 'Hide Form' : 'Add Staff'}
          </ToggleButton>
          <ToggleButton onClick={() => setShowDetailsForm(!showDetailsForm)}>
            {showDetailsForm ? 'Hide Details' : 'View Details'}
          </ToggleButton>
        </div>
      </Header>

      {showForm && (
        <Form onSubmit={handleAddStaff}>
          <Input
            type="text"
            placeholder="Staff Name"
            value={staffName}
            onChange={(e) => setStaffName(e.target.value)}
            required
          />
          <Button type="submit">Add Staff</Button>
        </Form>
      )}

      {showDetailsForm && (
        <ViewDetailsForm>
          <Dropdown
            value={selectedStaff}
            onChange={(e) => setSelectedStaff(e.target.value)}
          >
            {staff.map((member, index) => (
              <option key={index} value={member.name}>{member.name}</option>
            ))}
          </Dropdown>
          <Input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <Input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
          <Button onClick={handleViewDetails}>View Details</Button>

          {Object.keys(detailedAttendance).length > 0 && (
            <AttendanceTable>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(detailedAttendance).map(([date, status], index) => (
                  <tr key={index}>
                    <td>{date}</td>
                    <td>{status}</td>
                  </tr>
                ))}
              </tbody>
            </AttendanceTable>
          )}
        </ViewDetailsForm>
      )}

      <AttendanceFilter>
        <Input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
      </AttendanceFilter>

      <StaffContainer>
        {staff.map((member, index) => (
          <StaffBox key={index}>
            <StaffBoxHeader>
              <h3>{member.name}</h3>
            </StaffBoxHeader>
            <p>Days Present this Month: {getDaysPresent(member.attendance)}</p>
            <AttendanceTable>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(member.attendance).map((date, i) => (
                  <tr key={i}>
                    <td>{date}</td>
                    <td>{member.attendance[date]}</td>
                  </tr>
                ))}
              </tbody>
            </AttendanceTable>
            <div>
              <RadioButton
                type="radio"
                id={`present-${index}`}
                name={`attendance-${index}`}
                value="P"
                onChange={() => handleAttendanceChange(index, selectedDate, 'P')}
              />
              <label htmlFor={`present-${index}`}>Present</label>
              <RadioButton
                type="radio"
                id={`absent-${index}`}
                name={`attendance-${index}`}
                value="A"
                onChange={() => handleAttendanceChange(index, selectedDate, 'A')}
              />
              <label htmlFor={`absent-${index}`}>Absent</label>
            </div>
          </StaffBox>
        ))}
      </StaffContainer>
      <Button onClick={handleSaveAttendance}>Save Attendance</Button>
    </Container>
  );
};

export default StaffAttendance;