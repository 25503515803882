import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs, setDoc,deleteDoc, doc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useTable, useSortBy } from 'react-table';
import moment from 'moment';
import styled, { css } from 'styled-components';
import Modal from './Modal';
import AddUserPage from './AddUserPage';
import '../css/UserTable.css';
import'../css/Modal.css';



// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyAyzAXx7lXVKVNoSfKuzbz8cPx47tT-nXM",
    authDomain: "zi-goals.firebaseapp.com",
    projectId: "zi-goals",
    storageBucket: "zi-goals.appspot.com",
    messagingSenderId: "870416339071",
    appId: "1:870416339071:web:6b4f84985abefec96baf4a"
  };
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const Title = styled.h2`
  color: #333;
`;

const FilterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterButton = styled.button`
  background-color: ${({ active }) => (active ? '#ff5757' : '#fff')};
  color: ${({ active }) => (active ? '#fff' : '#ff5757')};
  border: 1px solid #ff5757;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  font-size: 14px;
  flex: 1 0 100px;

  &:hover {
    background-color: ${({ active }) => (active ? '#e64a4a' : '#ff5757')};
    color: #fff;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: auto;
`;

const Th = styled.th`
  background-color: #ff5757;
  color: #fff;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #fff;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px;
  }
`;

const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px;
  }
`;

const ActionButton = styled.button`
  background-color: #545454;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: #333;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 4px 8px;
  }
`;
const AddClientIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #ff5757;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
`;

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('all');
  const [adminEmail, setAdminEmail] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setAdminEmail(currentUser.email);
      } else {
        setAdminEmail(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchUsers = async () => {
    try {
      if (!adminEmail) return;

      const usersCollection = collection(firestore, 'addusers');
      let querySnapshot;
      const baseQuery = query(usersCollection, where('insertedBy', '==', adminEmail));

      const currentDate = moment().toDate(); // Ensure we use the current date properly
      const activeQuery = query(baseQuery, where('subscriptionEndDate', '>=', currentDate));
      const followupQuery = query(
        baseQuery,
        where('subscriptionEndDate', '>', currentDate),
        where('subscriptionEndDate', '<=', moment().add(15, 'days').toDate())
      );
      const subscriptionEndQuery = query(baseQuery, where('subscriptionEndDate', '<', currentDate));

      if (filter === 'active') {
        querySnapshot = await getDocs(activeQuery);
      } else if (filter === 'followup') {
        querySnapshot = await getDocs(followupQuery);
      } else if (filter === 'subscriptionEnd') {
        querySnapshot = await getDocs(subscriptionEndQuery);
      } else {
        querySnapshot = await getDocs(baseQuery);
      }

      const usersData = [];
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        usersData.push({
          id: doc.id,
          contactNo: userData.contactNo || '',
          name: userData.name || '',
          subscription: userData.subscription || '',
          class: userData.class || '',
          paidAmount: userData.paidAmount || '',
          paidDate: userData.paidDate ? userData.paidDate.toDate().toLocaleDateString() : '',
          subscriptionEndDate: userData.subscriptionEndDate ? userData.subscriptionEndDate.toDate().toLocaleDateString() : '',
          imageUrl: userData.imageUrl || 'https://firebasestorage.googleapis.com/v0/b/zi-goals.appspot.com/o/Capture3.PNG?alt=media&token=13b24af5-57a6-420c-bb73-5ad378306ef2',
        });
      });

      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [filter, adminEmail]);

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const handleAddUser = () => {
    setEditingUser(null);
    setIsModalOpen(true);
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchUsers();
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteDoc(doc(firestore, 'addusers', userId));
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Contact No',
        accessor: 'contactNo',
      },
      {
        Header: 'Subscription',
        accessor: 'subscription',
        Cell: ({ row }) => `${row.original.subscription} - ${row.original.paidDate} - ${row.original.subscriptionEndDate}`,
      },
      {
        Header: 'Class',
        accessor: 'class',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div>
            <ActionButton onClick={() => handleEditUser(row.original)}>Edit</ActionButton>
            <ActionButton onClick={() => handleDeleteUser(row.original.id)}>Delete</ActionButton>
          </div>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => users, [users]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  return (
    <Container>
      <Header>
        {/* <Title>User Details</Title> */}
        <FilterButtons>
          <FilterButton
            onClick={() => handleFilterChange('all')}
            active={filter === 'all'}
          >
            All Users
          </FilterButton>
          <FilterButton
            onClick={() => handleFilterChange('active')}
            active={filter === 'active'}
          >
            Active Users
          </FilterButton>
          <FilterButton
            onClick={() => handleFilterChange('followup')}
            active={filter === 'followup'}
          >
            Followup Users
          </FilterButton>
          <FilterButton
            onClick={() => handleFilterChange('subscriptionEnd')}
            active={filter === 'subscriptionEnd'}
          >
            Subscription End Users
          </FilterButton>
        </FilterButtons>
      </Header>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </Th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <AddClientIcon onClick={handleAddUser}>+</AddClientIcon>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <AddUserPage user={editingUser} />
      </Modal>
    </Container>
  );
};

export default UsersTable;