import React, { useEffect, useState } from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Icon from 'react-native-vector-icons/FontAwesome';

const firebaseConfig = {
  apiKey: "AIzaSyAyzAXx7lXVKVNoSfKuzbz8cPx47tT-nXM",
  authDomain: "zi-goals.firebaseapp.com",
  projectId: "zi-goals",
  storageBucket: "zi-goals.appspot.com",
  messagingSenderId: "870416339071",
  appId: "1:870416339071:web:6b4f84985abefec96baf4a"
};
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

const CustomDrawerContent = (props) => {
  const [adminName, setAdminName] = useState('');
  const [adminLogo, setAdminLogo] = useState('');
  const [companyLogo, setCompanyLogo] = useState('https://firebasestorage.googleapis.com/v0/b/zi-goals.appspot.com/o/company_logo.PNG?alt=media&token=13b24af5-57a6-420c-bb73-5ad378306ef2');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const adminEmail = currentUser.email;
        try {
          const adminsCollection = collection(firestore, 'admins'); // Replace 'admins' with your actual Firestore collection name
          const adminQuery = query(adminsCollection, where('admin_mail_id', '==', adminEmail));
          const querySnapshot = await getDocs(adminQuery);

          if (!querySnapshot.empty) {
            const adminData = querySnapshot.docs[0].data();
            setAdminName(adminData.admin_name || 'Zi-Goals');
            setAdminLogo(adminData.adminlogo || 'https://firebasestorage.googleapis.com/v0/b/zi-goals.appspot.com/o/Capture3.PNG?alt=media&token=13b24af5-57a6-420c-bb73-5ad378306ef2');
          }
        } catch (error) {
          console.error('Error fetching admin details:', error);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <DrawerContentScrollView {...props}>
      <View style={styles.drawerHeader}>
        <Image source={{ uri: adminLogo }} style={styles.logo} />
        <Text style={styles.adminName}>{adminName}</Text>
        <View style={styles.separator} />
      </View>
      <DrawerItemList {...props} />
      <View style={styles.footer}>
        <Image source={{ uri: companyLogo }} style={styles.footerLogo} />
      </View>
    </DrawerContentScrollView>
  );
};

const styles = StyleSheet.create({
  drawerHeader: {
    height: 150,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    paddingVertical: 20,
  },
  logo: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  adminName: {
    color: '#545454',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
  },
  separator: {
    height: 1,
    backgroundColor: '#ccc',
    width: '80%',
    marginVertical: 10,
  },
  footer: {
    alignItems: 'center',
    padding: 20,
  },
  footerLogo: {
    width: 100,
    height: 100,
  },
});

export default CustomDrawerContent;