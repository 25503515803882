import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { Image } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import DashboardPage from './DashboardPage';
import AdminPage from './AdminPage';
import AddUserPage from './AddUserPage';
import ViewUsersPage from './ViewUsersPage';
import ExpensePage from './ExpensePage';
import StaffAttendance from './StaffAttendance';
import SubscriptionPlans from './SubscriptionPlans';
import CustomDrawerContent from './CustomerDrawerContent'; // Import your custom drawer content component
import DashboardIcon from '../../assets/icons/Menuicons/dashboard.png'; // Adjust the path as needed
import UserIcon from '../../assets/icons/Menuicons/adduser.png'; // Adjust the path as needed
import UserPlusIcon from '../../assets/icons/Menuicons/adduser.png'; // Adjust the path as needed
import MoneyIcon from '../../assets/icons/Menuicons/expense.png'; 
import attendance from '../../assets/icons/Menuicons/attendance.png';
import subscription from '../../assets/icons/Menuicons/subscription.png'; 
import SignOutPage from './SignOutPage'; // Adjust the path as needed
import UsersManagement from './UsersManagement';

const Drawer = createDrawerNavigator();
const MainDrawer = () => {
  return (
    <Drawer.Navigator
      initialRouteName="Summary"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <Drawer.Screen
        name="Summary"
        component={DashboardPage}
        options={{
          drawerIcon: ({ color, size }) => (
            <Image source={DashboardIcon} style={{ width: size, height: size, tintColor: color }} />
          ),
        }}
      />
      {/* Uncomment and adjust the following if you have an AddUserPage */}
      {/* <Drawer.Screen
        name="Add User Page"
        component={AddUserPage}
        options={{
          drawerIcon: ({ color, size }) => (
            <Image source={UserIcon} style={{ width: size, height: size, tintColor: color }} />
          ),
        }}
      /> */}
      <Drawer.Screen
        name="Add/ViewUsers"
        component={ViewUsersPage}
        options={{
          drawerIcon: ({ color, size }) => (
            <Image source={UserPlusIcon} style={{ width: size, height: size, tintColor: color }} />
          ),
        }}
      />
      <Drawer.Screen
        name="Subscription"
        component={SubscriptionPlans}
        options={{
          drawerIcon: ({ color, size }) => (
            <Image source={subscription} style={{ width: size, height: size, tintColor: color }} />
          ),
        }}
      />
      <Drawer.Screen
        name="UsersManagement"
        component={UsersManagement}
        options={{
          drawerIcon: ({ color, size }) => (
            <Image source={UserPlusIcon} style={{ width: size, height: size, tintColor: color }} />
          ),
        }}
      />
      <Drawer.Screen
        name="StaffAttendance"
        component={StaffAttendance}
        options={{
          drawerIcon: ({ color, size }) => (
            <Image source={attendance} style={{ width: size, height: size, tintColor: color }} />
          ),
        }}
      />
      <Drawer.Screen
        name="Expense"
        component={ExpensePage}
        options={{
          drawerIcon: ({ color, size }) => (
            <Image source={MoneyIcon} style={{ width: size, height: size, tintColor: color }} />
          ),
        }}
      />
      <Drawer.Screen
        name="SignOut"
        component={SignOutPage}
        options={{
          drawerIcon: ({ color, size }) => (
            <Image source={UserIcon} style={{ width: size, height: size, tintColor: color }} />
          ),
          title: 'Sign Out'
        }}
      />
    </Drawer.Navigator>
  );
};
export default MainDrawer;
